<template>
  <el-card class="card">
    <div slot="header" class="clearfix">
      <el-form v-model="sourcecallBack" :inline="true" label-width="100px">
        <el-row>
          <el-col :lg="6" :md="20">
            <el-form-item label="OfferId:">
              <el-input
                v-model="sourcecallBack.offerId"
                style="width:3rem;"
                placeholder="offerId here"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="10" :md="20">
            <el-form-item label-width="80px">
              <el-button type="primary" @click="makeTestLinkClick">Make TestLink</el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-form :model="checkOfferTest" label-width="100px" :inline="true">
            <el-row>
              <el-form-item label="TestLink:">
                <el-input
                  v-model="checkOfferTest.requestUrl"
                  style="width:440px;"
                  placeholder="link here"
                ></el-input>
              </el-form-item>
            </el-row>
            <el-row>
              <el-col :lg="10" :md="20">
                <el-form-item label="Platform:">
                  <el-select v-model="checkOfferTest.platform" filterable clearable>
                    <el-option
                      v-for="item in platformOptions"
                      :key="item.value"
                      :value="item.value"
                      :label="item.label"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :lg="10" :md="20">
                <el-form-item label="Country:">
                  <el-select v-model="checkOfferTest.country" filterable clearable>
                    <el-option
                      v-for="item in countryOptions"
                      :key="item.value"
                      :value="item.value"
                      :label="item.label"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :lg="3" :md="20" :offset="1">
                <el-form-item>
                  <el-button type="primary" @click="sumbitOfferClick" :disabled="sumbitOfferdisbled"
                    >SUBMIT</el-button
                  >
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-row>
      </el-form>
    </div>
    <div class="content">
      <p
        >跳转状态：
        <span v-if="jumpResult.status === 1">跳转成功</span>
        <span v-else-if="jumpResult.status === 0">跳转失败</span>
        <span v-else-if="jumpResult.status !== ''">跳转异常</span>
      </p>
      <p> 跳转详情：<span v-html="fillDomains(jumpResult.detail)"></span></p>
    </div>
  </el-card>
</template>

<script>
  import { getMacklink, linkTest } from 'api/callback';
  import optionData from '@/assets/js/optionData';
  export default {
    data() {
      return {
        sourcecallBack: {
          offerId: '',
        },
        checkOfferTest: {
          requestUrl: '',
          platform: 'android',
          country: 'us',
        },
        platformOptions: [
          {
            label: 'Android',
            value: 'android',
          },
          {
            label: 'IOS',
            value: 'ios',
          },
        ],
        countryOptions: optionData.countryOptions,
        submitData: [],
        sumbitOfferdisbled: false,
        jumpResult: {
          status: '',
          detail: '',
        },
      };
    },
    methods: {
      handleSelectChange(val) {
        this.sourceIdArray = [];
        for (var i = 0; i < this.sourcesList.length; i++) {
          for (var j = 0; j < this.sourcesList[i].children.length; j++) {
            if (this.sourcesList[i].children[j].value == val) {
              this.type = this.sourcesList[i].label;
            }
          }
        }

        for (let i = 0; i < val.length; i++) {
          for (let j = 0; j < JSON.parse(val[i]).length; j++) {
            this.sourceIdArray.push(JSON.parse(val[i])[j]);
          }
        }
      },
      makeTestLinkClick() {
        const offerId = this.sourcecallBack.offerId.trim();
        if (!offerId) {
          this.$message.warning('do not choose offerId!');
          return;
        }

        getMacklink(offerId).then((response) => {
          if (response.code === 200) {
            this.checkOfferTest.requestUrl = response.result.requestUrl;
          } else {
            this.$message.error('error:' + response.message);
          }
        });
      },
      sumbitOfferClick() {
        this.sumbitOfferdisbled = true;
        this.$message({
          message: '正在加载中，请稍候...',
          type: 'warning',
        });
        const param = {
          ...this.checkOfferTest,
        };
        linkTest(param)
          .then((response) => {
            const result = response.result;
            this.jumpResult.status = result.status;
            this.jumpResult.detail = result.domains;
            if (response.code !== 200) {
              this.$message.error(response.message);
            }
          })
          .finally(() => {
            this.sumbitOfferdisbled = false;
          });
      },
      fillImages(images) {
        var html = '';
        if (images != null) {
          images.split(',').forEach((item, i) => {
            html += "<div style='float:left;'><img src='" + item + "' width='50' height='50' /> ";
            if (i < images.split(',').length - 1) {
              html += "<i class='el-icon-right' style='font-size:20px'></i>";
            }
            html += '</div>';
          });
        }
        return html;
      },
      fillDomains(domains) {
        var html = '';
        if (domains != null) {
          domains.split(',').forEach((item, i) => {
            html +=
              '<span style="word-wrap:break-word;word-break:break-all;white-space: normal">' +
              item +
              '</span>';
            if (i < domains.split(',').length - 1) {
              html += "<i class='el-icon-right' style='font-size:20px'></i>";
            }
          });
        }
        return html;
      },
    },
  };
</script>

<style lang="scss">
  .card.el-card {
    border: none;
  }
  .content {
    padding-left: 25px;
    padding-right: 25px;
    font-size: 16px;
    line-height: 25px;
  }
</style>
