<template>
  <div>
    <el-row style="margin-bottom:40px;">
      <el-form v-model="callBackMessages" :inline="true" label-width="100px">
        <el-form-item label="Affiliate(s):" label-width="110px">
          <group-select
            v-model="callBackMessages.affiliates"
            :data-source="affGroupList"
            :loading="affLoading"
            clearable
            filterable
            placeholder="请选择"
            style="width:210px;"
            @change="handleSelectChange"
          />
        </el-form-item>
        <el-form-item label="PostBack:">
          <el-input v-model="callBackMessages.postBackUrl" placeholder="postback here"></el-input>
        </el-form-item>
        <el-form-item label="TestLink:">
          <el-input v-model="callBackMessages.requestUrl" placeholder="testlink here"></el-input>
        </el-form-item>
        <el-form-item label="Platform:">
          <el-select v-model="callBackMessages.platform" filterable clearable style="width: 220px;">
            <el-option
              v-for="item in platformOptions"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Country:">
          <el-select v-model="callBackMessages.country" filterable clearable style="width: 210px;">
            <el-option
              v-for="item in countryOptions"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-col :offset="1">
          <el-form-item>
            <el-button type="primary" @click="affiliateTest">Callback</el-button>
          </el-form-item>
        </el-col>
      </el-form>
    </el-row>
    <el-row>
      <div
        class="showmsg"
        style="margin-left:40px; font-size:16px;line-height:1.5em"
        v-html="showmsg"
      ></div>
    </el-row>
  </div>
</template>

<script>
  import GroupSelect from '@/components/GroupSelect';

  import optionData from '@/assets/js/optionData';
  import { affiliateTest } from 'api/callback';
  import { mapState, mapActions } from 'vuex';
  export default {
    components: {
      GroupSelect,
    },
    props: {
      affLoading: {
        type: Boolean,
        default: true,
      },
      affGroupList: {
        type: Array,
        default: () => [],
      },
      affiliates: {
        type: Array,
        default: () => [],
      },
    },
    computed: {
      ...mapState('affiliate', {
        affInfoList: (state) => state.affiliates,
      }),
    },
    data() {
      return {
        callBackMessages: {
          affiliates: null,
          postBackUrl: null,
          requestUrl: null,
          platform: null,
          country: null,
        },
        platformOptions: [
          {
            label: 'Android',
            value: 'android',
          },
          {
            label: 'IOS',
            value: 'IOS',
          },
        ],
        countryOptions: optionData.countryOptions,
        showmsg: null,
      };
    },
    methods: {
      ...mapActions('affiliate', ['getAffiliateInfoList']),
      affiliateTest() {
        const param = {
          postBackUrl: this.callBackMessages.postBackUrl,
          requestUrl: this.callBackMessages.requestUrl,
          platform: this.callBackMessages.platform,
          country: this.callBackMessages.country,
        };
        affiliateTest(param).then((response) => {
          if (response.code === 200) {
            const result = response.result;
            const { body = '', success = '', postback = '' } = result;
            this.showmsg = `body:${body}</br>success:${success}</br>postback:${postback}`;
          } else {
            this.$message.error(response.message);
          }
        });
      },
      handleSelectChange(value) {
        for (const item of this.affInfoList) {
          if (item.id === value) {
            this.callBackMessages.postBackUrl = item.postbackUrl ? item.postbackUrl : '';
            break;
          }
        }
      },
    },
    mounted() {
      this.getAffiliateInfoList();
    },
  };
</script>

<style></style>
