<template>
  <div>
    <el-form :model="checkAffiliTest" label-width="100px" :inline="true">
      <el-row>
        <el-col :lg="10" :md="20">
          <el-form-item label="OfferId:">
            <el-input v-model="checkAffiliTest.offerId" placeholder="offerId here"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :lg="3" :md="20" :offset="1">
          <el-form-item>
            <el-button type="primary" :disabled="testClickDisabled" @click="sumbitClick"
              >SUBMIT</el-button
            >
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-table
      v-loading="listLoading"
      style="width:100%;"
      height="40vmin"
      stripe
      border
      :data="affiliateTestList"
      highlight-current-row
    >
      <el-table-column label="OfferId" prop="offerId"></el-table-column>
      <el-table-column label="Status" prop="status" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status == 0" type="danger" align="center">跳转失败</el-tag>
          <el-tag v-else-if="scope.row.status == 1" type="success" align="center">跳转成功</el-tag>
          <el-tag v-else type="info">跳转异常</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        label="Redirects Number"
        prop="redirectsNum"
        align="center"
      ></el-table-column>
      <el-table-column label="Test Number" prop="testNum" align="center"></el-table-column>
      <el-table-column label="Tracking Domain" align="center">
        <template slot-scope="scope">
          <div v-html="fillDomains(scope.row.domains)"></div>
        </template>
      </el-table-column>

      <el-table-column label="Update Time">
        <template slot-scope="scope">
          <span>{{ scope.updateTime ? scope.updateTime : scope.createTime }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Option" align="center">
        <template slot-scope="scope">
          <el-button type="primary" @click="updateClick(scope.row)">Update</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
  import { getOfferTestResult, updateOfferTestResult } from 'api/callback';
  export default {
    data() {
      return {
        checkAffiliTest: {
          offerId: null,
        },
        testClickDisabled: false,
        listLoading: false,
        affiliateTestList: [],
      };
    },
    methods: {
      sumbitClick() {
        this.listLoading = true;
        const param = {
          ...this.checkAffiliTest,
        };

        this.testClickDisabled = true;
        getOfferTestResult(param).then((response) => {
          this.listLoading = false;
          this.testClickDisabled = false;
          if (response.code === 200) {
            this.affiliateTestList = [response.result];
          } else {
            this.$message.error(response.message);
          }
        });
      },
      fillImages(images) {
        var html = '';
        if (images != null) {
          images.split(',').forEach((item, i) => {
            html += "<div style='float:left;'><img src='" + item + "' width='50' height='50' /> ";
            if (i < images.split(',').length - 1) {
              html += "<i class='el-icon-right' style='font-size:20px'></i>";
            }
            html += '</div>';
          });
        }
        return html;
      },
      fillDomains(domains) {
        var html = '';
        if (domains != null) {
          domains.split(',').forEach((item, i) => {
            html += "<div style='float:left;'><span>" + item + '</span>';
            if (i < domains.split(',').length - 1) {
              html += "<i class='el-icon-right' style='font-size:20px'></i>";
            }
            html += '</div>';
          });
        }
        return html;
      },
      updateClick(row) {
        const offerId = row.offerId;
        updateOfferTestResult(offerId).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: 'Update Success',
              type: 'success',
            });
            this.affiliateTestList = [response.result];
          } else {
            this.$message.error('Update Error:' + response.message);
          }
        });
      },
    },
  };
</script>

<style></style>
