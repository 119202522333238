<template>
  <div>
    <el-form label-width="100px" :inline="true">
      <el-form-item label="Source" label-width="74px">
        <el-select
          v-model="resultSourceId"
          :loading="sourceLoading"
          clearable
          filterable
          placeholder="请选择"
        >
          <el-option-group v-for="group in sourcesList" :key="group.label" :label="group.label">
            <el-option
              v-for="item in group.children"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-option-group>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" :disabled="offerResultDisabled" @click="offerTestResult"
          >Search</el-button
        >
      </el-form-item>
    </el-form>
    <el-table
      border
      stripe
      :data="dataList"
      v-loading="resultLoading"
      highlight-current-row
      style="width:100%;"
      height="70vmin"
    >
      <el-table-column label="Count" prop="count" align="center"></el-table-column>
      <el-table-column label="Status" prop="status" align="center">
        <template slot-scope="scope" align="center">
          <el-tag v-if="scope.row.status == 0 || scope.row.status == -1" type="danger"
            >跳转失败</el-tag
          >
          <el-tag v-else-if="scope.row.status == '1'" type="success">跳转成功</el-tag>
          <el-tag v-else type="info">暂未测试</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="Action" align="center">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.status == null && scope.row.status == undefined"
            type="info"
            @click="delOfferTestResultClick(scope.row)"
            disabled
            >Delete</el-button
          >
          <el-button v-else type="primary" @click="delOfferTestResultClick(scope.row)"
            >Delete</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 删除提示框 -->
    <el-dialog
      title="Delete"
      :visible.sync="delOfferTestResultVisible"
      width="300px"
      append-to-body
    >
      <div class="del-dialog-cnt"
        >Confirm to <span> SourceId: {{ resultSourceId }} </span> delete ?</div
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="delOfferTestResultVisible = false">cancel</el-button>
        <el-button type="primary" @click="delOfferTestResultRow">confirm</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { getSourceTestResult, deleteOfferTest } from 'api/callback';

  export default {
    props: {
      sourceLoading: {
        type: Boolean,
        default: false,
      },
      sourcesList: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        resultSourceId: null,
        offerResultDisabled: false,
        dataList: [],
        resultLoading: false,
        delOfferTestResultVisible: false,
      };
    },
    methods: {
      offerTestResult() {
        this.resultLoading = true;
        this.offerResultDisabled = true;
        if (this.resultSourceId !== null && this.resultSourceId !== '') {
          getSourceTestResult(this.resultSourceId).then((response) => {
            this.resultLoading = false;
            this.offerResultDisabled = false;
            if (response.code === 200) {
              this.dataList = response.result;
            } else {
              this.$message.error(response.message);
            }
          });
        } else {
          this.$message.error('请输入SourceId再试');
          this.resultLoading = false;
          this.offerResultDisabled = false;
        }
      },
      delOfferTestResultClick(row) {
        this.delOfferTestResultVisible = true;
        this.delStatus = row.status;
      },
      delOfferTestResultRow() {
        deleteOfferTest(this.resultSourceId, this.delStatus).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: 'Delete Success',
              type: 'success',
            });
            this.offerTestResult();
          } else {
            this.$message.error('Delete Error:' + response.message);
          }
        });
        this.delOfferTestResultVisible = false;
        this.delStatus = null;
      },
    },
  };
</script>

<style></style>
