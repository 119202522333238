<template>
  <div class="postbackIntegration">
    <div class="title">
      <h2>Callback Test / Check</h2>
    </div>
    <el-tabs type="card" v-model="activeName" class="pad30">
      <el-tab-pane label="Affiliate Callback Test" name="first">
        <aff-callback-test
          :affLoading="affiliateLoading"
          :affGroupList="affiliatesGroupList"
          :affiliates="affiliates"
        />
      </el-tab-pane>
      <el-tab-pane label="Source Callback Test" name="second" lazy>
        <source-callback-test :sourceLoading="sourceLoading" :sourcesList="sourcesList" />
      </el-tab-pane>
      <el-tab-pane label="Offer Test" name="third" lazy>
        <offer-test />
      </el-tab-pane>
      <el-tab-pane label="Offer Test Result" name="fourth" lazy>
        <offer-test-result :sourceLoading="sourceLoading" :sourcesList="sourcesList" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
  import optionData from '@/assets/js/optionData';
  import { mapState, mapActions } from 'vuex';
  import AffCallbackTest from './AffCallbackTest.vue';
  import SourceCallbackTest from './SourceCallbackTest.vue';
  import OfferTest from './OfferTest.vue';
  import OfferTestResult from './OfferTestResult.vue';
  export default {
    components: {
      AffCallbackTest,
      SourceCallbackTest,
      OfferTest,
      OfferTestResult,
    },
    data() {
      return {
        AffCallbackTesteName: 'first',
        sourceIdArray: [],
        countryOptions: optionData.countryOptions,
        type: '',
        testClickDisabled: false,
        activeName: 'second',
      };
    },
    computed: {
      ...mapState('affiliate', {
        affiliatesGroupList: (state) => state.affiliatesGroupList,
        affiliateLoading: (state) => state.affiliateLoading,
        affiliates: (state) => state.affiliates,
      }),
      ...mapState('source', {
        sourcesList: (state) => state.sourcesList,
        sourceLoading: (state) => state.sourceLoading,
      }),
    },
    mounted() {
      this.getAffiliateList();
      this.getSourceIdsList();
    },

    methods: {
      ...mapActions('affiliate', ['getAffiliateList']),
      ...mapActions('source', ['getSourceIdsList']),
    },
  };
</script>
<style lang="scss" scoped></style>
