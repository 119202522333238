import request from 'utils/request';

const api = {
  AFFILIATE_TEST: '/api/callback/affiliate/test',
  MACKLINK: '/api/callback/offer/makeLink',
  LINK_TEST: '/api/callback/link/test',
  OFFER_TEST_RESULT: '/api/callback/offer/test/result',
  UPDATE_OFFER_TEST_RESULT: '/api/callback/offer/test/update',
  SOURCE_TEST_RESULT: '/api/callback/source/test/result',
  DELETE_SOURCE_TEST: '/api/callback/source/test/delete',
};

export function affiliateTest(query) {
  return request({
    url: api.AFFILIATE_TEST,
    method: 'get',
    params: query,
  });
}

export function getMacklink(offerId) {
  return request({
    url: api.MACKLINK,
    method: 'get',
    params: {
      offerId,
    },
  });
}

export function linkTest(query) {
  return request({
    url: api.LINK_TEST,
    method: 'get',
    params: query,
  });
}

export function getOfferTestResult(query) {
  return request({
    url: api.OFFER_TEST_RESULT,
    method: 'get',
    params: query,
  });
}

export function updateOfferTestResult(offerId) {
  return request({
    url: api.UPDATE_OFFER_TEST_RESULT,
    method: 'put',
    data: {
      offerId,
    },
  });
}

export function getSourceTestResult(sourceId) {
  return request({
    url: api.SOURCE_TEST_RESULT,
    method: 'get',
    params: {
      sourceId,
    },
  });
}

export function deleteOfferTest(sourceId, status) {
  return request({
    url: api.DELETE_SOURCE_TEST,
    method: 'delete',
    params: {
      sourceId,
      status,
    },
  });
}
